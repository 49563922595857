var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c("c-table", {
            ref: "mstTable",
            attrs: {
              title: "기계장비유형",
              tableId: "mstTable",
              columnSetting: false,
              isFullScreen: false,
              usePaging: false,
              filtering: false,
              columns: _vm.mstGrid.columns,
              isExcelDown: false,
              data: _vm.mstGrid.data,
            },
            on: { rowClick: _vm.rowClick },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    [
                      col.name === "openIns" && props.row.editFlag != "C"
                        ? _c(
                            "q-chip",
                            {
                              attrs: {
                                color: "blue",
                                clickable: true,
                                "text-color": "white",
                              },
                            },
                            [_vm._v(" ▶ ")]
                          )
                        : _vm._e(),
                    ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
        [
          _c(
            "c-table",
            {
              ref: "grid",
              attrs: {
                title: "유형별 점검항목",
                tableId: "table",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                selection: "single",
                rowKey: "hazardousMachineryTypeInsId",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable &&
                      _vm.selectedRows.code &&
                      _vm.selectedRows.editFlag != "C"
                        ? _c("c-btn", {
                            attrs: {
                              label: "동일구분 점검항목추가",
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addrowCopy },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      _vm.selectedRows.code &&
                      _vm.selectedRows.editFlag != "C"
                        ? _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                            on: { btnClicked: _vm.addrowIns },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      _vm.selectedRows.code &&
                      _vm.selectedRows.editFlag != "C"
                        ? _c("c-btn", {
                            attrs: { label: "저장", icon: "save" },
                            on: { btnClicked: _vm.saveIns },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      _vm.selectedRows.code &&
                      _vm.selectedRows.editFlag != "C"
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                            on: { btnClicked: _vm.removeRowIns },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }